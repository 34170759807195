import {STORE_USER, USER_ROLE, USER_ROLES} from "../../types";

let defaultState = {
  user: undefined,
  roles:undefined,
  role:undefined
}

export const user = (state = defaultState, action) => {
  switch (action.type) {
    case STORE_USER: {
      return {...state, user: action.payload }
    }
    case USER_ROLES: {
      return {...state, roles: action.payload }
    }
    case USER_ROLE: {
      return {...state, role: action.payload }
    }
    default: {
      return state
    }
  }
}
