import {USER_INVITES, USER_LIST,COMPANY_USER_INVITES} from "../../types";

let defaultState =
{
  user_invites: undefined,
  user_list: undefined,
  company_invites: undefined,
}

 const users = (state = defaultState, action) => {
  switch (action.type) {
    case USER_LIST: {
      return {...state, user_list: action.payload }
    }
    case USER_INVITES: {
      return {...state, user_invites: action.payload }
    }
    case COMPANY_USER_INVITES: {
      return {...state, company_invites: action.payload }
    }
    default: {
      return state
    }
  }
}

export default users

