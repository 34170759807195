import {PARTNERS} from "../../types";

let defaultState =
  {
    list: undefined
  }

const partners = (state = defaultState, action) => {
  switch (action.type) {
    case PARTNERS: {
      return {...state, list: action.payload }
    }
    default: {
      return state
    }
  }
}

export default partners
