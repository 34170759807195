import {AUTOCENTERS} from "../../types";

let defaultState =
  {
    list: undefined
  }

const auto_centers = (state = defaultState, action) => {
  switch (action.type) {
    case AUTOCENTERS: {
      return {...state, list: action.payload }
    }
    default: {
      return state
    }
  }
}

export default auto_centers
