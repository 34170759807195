import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing"
import "./index.scss"
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
const {persistor,store} = require("./redux/storeConfig/store").default()
const LazyApp = lazy(() => import("./App"));

Sentry.init({
  dsn: "https://46a9d93c999b456d99fbf0104dbd02b2@o505912.ingest.sentry.io/5604165",
  autoSessionTracking: true,
  // integrations: [
  //   new Integrations.BrowserTracing(),
  // ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1.0,
});

// configureDatabase()
ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <Layout>
            <LazyApp />
        </Layout>
      </Suspense>
      </PersistGate>
    </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
