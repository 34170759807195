export const USER_INVITES = "USER_INVITES";
export const USER_LIST = "USER_LIST";
export const COMPANY_USER_INVITES = "COMPANY_USER_INVITES";
export const STORE_USER = "STORE_USER";
export const USER_ROLE = "USER_ROLE";
export const USER_ROLES = "USER_ROLES";
export const ROLE_LIST = "ROLE_LIST";
export const VEHICLE_TYPES = "VEHICLE_TYPES";
export const CHECKLIST = "CHECKLIST";
export const SYSTEM_ROLES = "SYSTEM_ROLES";
export const CHECK_SECTIONS = "CHECK_SECTIONS";
export const CHECKS = "CHECKS";
export const AUTOCENTERS = "AUTOCENTERS";
export const PARTNERS = "PARTNERS";
export const PAYSTACK_TRANSACTIONS = "PAYSTACK_TRANSACTIONS";
export const WALLET_TRANSACTIONS = "WALLET_TRANSACTIONS";
export const CHECKS_REPORTS = "CHECKS_REPORTS";



