import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["god", "member"],
  },
  {
    id: "auto_center",
    title: "Auto Center",
    type: "item",
    icon: <Icon.Compass size={20} />,
    permissions: ["god", "member"],
    navLink: "/autocenter",
  },
  {
    id: "partners",
    title: "Partners",
    type: "item",
    icon: <Icon.Folder size={20} />,
    permissions: ["god", "member"],
    navLink: "/partners",
  },
  {
    id: "forms-section",
    title: "Forms",
    type: "collapse",
    icon: <Icon.List size={17}/>,
    permissions: ["god", "administrator"],
    children: [
      {
        id: "forms",
        title: "Forms",
        type: "item",
        icon: <Icon.List size={17} />,
        permissions: ["god","administrator"],
        navLink: "/forms",
      },
      {
        id: "form-sections",
        title: "Sections",
        type: "item",
        icon: <Icon.Briefcase size={17} />,
        permissions: ["god","administrator"],
        navLink: "/form-sections",
      }
      ]
  },
  // {
  //   id: "forms",
  //   title: "Forms",
  //   type: "collapse",
  //   icon: <Icon.Check size={20}/>,
  //   permissions: ["god"],
  //   children: [
  //     {
  //       id: "forms",
  //       title: "Forms",
  //       type: "item",
  //       icon: <Icon.List size={17}/>,
  //       permissions: ["god", "member"],
  //       navLink: "/forms",
  //     },
      // {
      //   id: "checks",
      //   title: "Checks",
      //   type: "item",
      //   icon: <Icon.List size={17}/>,
      //   permissions: ["god", "member"],
      //   navLink: "/checks",
      // },
      // {
      //   id: "checklist-sections",
      //   title: "Sections",
      //   type: "item",
      //   icon: <Icon.List size={17}/>,
      //   permissions: ["god", "member"],
      //   navLink: "/checklist-sections",
      // }
  //   ]
  // },
  {
    id: "users",
    title: "Users",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    permissions: ["god"],
    children: [
      {
        id: "all-users",
        title: "All Users",
        type: "item",
        icon: <Icon.Users size={17} />,
        permissions: ["god"],
        navLink: "/users/all",
      },
      {
        id: "invited-users",
        title: "Invited Users",
        type: "item",
        icon: <Icon.Users size={17} />,
        permissions: ["god"],
        navLink: "/users/invites",
      },
/*      {
        id: "autocenter-users",
        title: "Auto Center",
        type: "item",
        icon: <Icon.Users size={17} />,
        permissions: ["god"],
        navLink: "/autocenter/invites",
      }*/
      ]
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    permissions: ["god"],
    navLink: "/settings",
    children: [
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <Icon.Briefcase size={17} />,
        permissions: ["god"],
        navLink: "/settings/roles",
      }
    ]
  },
  {
    id: "payments",
    title: "Payments",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["god", "member"],
    navLink: "/payments",
  },
]

export default navigationConfig
