import {CHECKLIST,CHECK_SECTIONS,CHECKS,CHECKS_REPORTS} from "../../types";

let defaultState =
  {
    checklist: undefined,
    check_sections: undefined,
    checks: undefined,
    checklist_reports: null,
  }

const checklist = (state = defaultState, action) => {
  switch (action.type) {
    case CHECKLIST: {
      return {...state, checklist: action.payload }
    }
    case CHECK_SECTIONS: {
      return {...state, check_sections: action.payload }
    }
    case CHECKS: {
      return {...state, checks: action.payload }
    }
    case CHECKS_REPORTS: {
      return {...state, checklist_reports: action.payload }
    }
    default: {
      return state
    }
  }
}

export default checklist

