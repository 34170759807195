import {ROLE_LIST, SYSTEM_ROLES} from "../../types";

let defaultState =
  {
    role_list: undefined,
    system_roles: undefined,
  }

const roles = (state = defaultState, action) => {
  switch (action.type) {
    case ROLE_LIST: {
      return {...state, role_list: action.payload }
    }
    case SYSTEM_ROLES: {
      return {...state, system_roles: action.payload }
    }
    default: {
      return state
    }
  }
}

export default roles

