import {createTokenProvider}  from "./tokenProvider"
import {useEffect, useState} from "react";
import HttpService from "../services/ApiService";
const {persistor,store} = require("../redux/storeConfig/store").default();

const createAuthProvider = (() => {
  const tokenProvider = createTokenProvider();
  const httpService = new HttpService();

  const getRole = async ()=>{
    let role = ""
    try {
      role = await store.getState().auth.user.role
    }catch (e) {

    }
    return role;
  }
  const login = (newTokens) => {
    tokenProvider.setToken(newTokens);
    window.location = "/"
  };

  const logout = () => {
    persistor.purge().then(()=>tokenProvider.setToken(null));
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());

    useEffect(() => {
      const listener = (newIsLogged) => {
        setIsLogged(newIsLogged);
      };

      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, []);

    return [isLogged];
  };

  const authGet = async (url) => {
    const role = await getRole();
    const token = await tokenProvider.getToken();
    return httpService.sendGet(url,token,role)
  };

  const authPost = async (url,data) => {
    const role = await getRole();
    const token = await tokenProvider.getToken();
    return httpService.sendPost(url,data,token, role)
  };

  const authPostNoContentType = async (url,data) => {
    const role = await getRole();
    const token = await tokenProvider.getToken();
    return httpService.sendPostNoContentType(url,data,token, role)
  };

  const authPut = async (url,data) => {
    const role = await getRole();
    const token = await tokenProvider.getToken();
    return httpService.sendPut(url,data,token, role)
  };

  const authGetBlob = async (url,data) => {
    const role = await getRole();
    const token = await tokenProvider.getToken();
    return httpService.getBlob(url,token,role)
  };

  const authDelete = async (url,data) => {
    const role = await getRole();
    const token = await tokenProvider.getToken();
    return httpService.sendDelete(url,data,token,role)
  };

  return {
    useAuth,
    authGet,
    authPost,
    login,
    authPut,
    logout,
    authDelete,
    authPostNoContentType,
    authGetBlob
  }
})();

export default createAuthProvider;
