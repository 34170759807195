import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import users from "./users/"
import roles from "./roles/"
import vehicles from "./vehicles/"
import checklist from "./checklist/"
import auto_centers from "./autocenters/"
import partners from "./partners/"
import payment from "./payment/index"
import navbar from "./navbar/Index"

const rootReducer = combineReducers({
  customizer: customizer,
  users: users,
  auth: auth,
  navbar: navbar,
  roles: roles,
  vehicles: vehicles,
  checklist: checklist,
  auto_centers: auto_centers,
  partners: partners,
  payment: payment,
})

export default rootReducer
