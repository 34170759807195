import {PAYSTACK_TRANSACTIONS, WALLET_TRANSACTIONS} from "../../types";

let defaultState =
  {
    paystack_transactions: undefined,
    wallet_transactions: undefined,
  }

const payment = (state = defaultState, action) => {
  switch (action.type) {
    case PAYSTACK_TRANSACTIONS: {
      return {...state, paystack_transactions: action.payload }
    }
    case WALLET_TRANSACTIONS: {
      return {...state, wallet_transactions: action.payload }
    }
    default: {
      return state
    }
  }
}

export default payment

