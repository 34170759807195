const {persistor,store} = require("../redux/storeConfig/store").default();
export const createTokenProvider = () => {
  const baseUrl =  "https://api.checklist.motocheck.ng/"
  // const baseUrl =  "http://localhost:7700/"
  let observers = [];
  let _token = localStorage.getItem('TOKEN_AUTH') ?  JSON.parse(localStorage.getItem('TOKEN_AUTH')) :  ''

  const getExpirationDate = (jwtToken)=> {
    if (!jwtToken) {
      return null;
    }

    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return jwt && jwt.exp && jwt.exp * 1000 || null;
  };

  const isExpired = (exp) => {
    if (!exp) {
      return false;
    }

    return Date.now() > exp;
  };

  const isLoggedIn = () => {
    return !!_token;
  };

  const getToken = async () => {
    if (!_token) {
      return null;
    }

    if (isExpired(getExpirationDate(_token.access_token))) {
      var params = {
        grant_type: "refresh_token",
        client_id:4,
        client_secret:"wKPSIfomiN8qhuTHamEIyJJ0Q1Pwh9vnP1jkudg9",
        refresh_token : _token.refresh_token,
        scope:"",
      };

      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(params)
      };

      await fetch(baseUrl+'auth/token', request)
        .then(r => r.json())
        .then(r => {r.hasOwnProperty('access_token') && setToken(r)}
        )
        .catch( () => {
            persistor.purge().then(()=>setToken(null));
        })

    }

    return _token && _token.access_token;
  };

  const subscribe = (observer) => {
    observers.push(observer);
  };

  const unsubscribe = (observer) => {
    observers = observers.filter(_observer => _observer !== observer);
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    observers.forEach(observer => observer(isLogged));
  };

  const setToken = (token) => {
    if (token) {
      localStorage.setItem('TOKEN_AUTH', JSON.stringify(token));
    } else {
      localStorage.removeItem('TOKEN_AUTH');
    }
    _token = token;
    notify();
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};

