class HttpService {
    constructor(){
        this.sendPost = this.sendPost.bind(this);
        this.sendDelete = this.sendDelete.bind(this);
        this.sendGet = this.sendGet.bind(this);
        this.sendPut = this.sendPut.bind(this);
        this.getBlob = this.getBlob.bind(this);
        this.baseUrl =  process.env.REACT_APP_BASE_URL
        // this.baseUrl =  "https://api.checklist.motocheck.ng/"
    }

    sendPost = async (url, postData,token, role) => {
      const request = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-using-role': role
        },
        body:JSON.stringify(postData)
      };

      return await fetch(this.baseUrl+url, request)
        .then(res=>res.json())
    }

  sendPostNoContentType = async (url, postData,token, role) => {
    const request = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-using-role': role
      },
      body:postData
    };

    return await fetch(this.baseUrl+url, request)
      .then(res=>res.json())
  }

    LoginAuth = async (postData) => {
      var params = {
        username: postData.username,
        password: postData.password,
        grant_type: "password",
        client_id:4,
        client_secret:"wKPSIfomiN8qhuTHamEIyJJ0Q1Pwh9vnP1jkudg9",
        scope:"",
      };

      let formBody = [];
      for (const property in params) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      var request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      };

      return await fetch(this.baseUrl+"auth/token", request)
        .then(res=>res.json())

    }

    getNoAuth = async (token = null) => {
      const request = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return await fetch(this.baseUrl+"auth/me", request)
        .then(res=>res.json())
    }

    sendGet = async (url,token,role) => {
      const request = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-using-role': role
        }
      };
      return await fetch(this.baseUrl+url, request)
        .then(res=>res.json())
    }

    getBlob = async (url,token,role) => {
      const request = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-using-role': role
        }
      };
      return await fetch(this.baseUrl+url, request)
    }

    sendPut = async (url, postData,token,role) => {
    const request = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-using-role': role
      },
      body:JSON.stringify(postData)
    };

    return await fetch(this.baseUrl+url, request)
      .then(res=>res.json())
  }

    sendDelete = async (url, postData,token,role) => {
    const request = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-using-role': role
      },
      body:JSON.stringify(postData)
    };

    return await fetch(this.baseUrl+url, request)
      .then(res=>res.json())
  }
}


export default HttpService;
