import {VEHICLE_TYPES} from "../../types";

let defaultState =
  {
    vehicle_types: undefined
  }

const vehicles = (state = defaultState, action) => {
  switch (action.type) {
    case VEHICLE_TYPES: {
      return {...state, vehicle_types: action.payload }
    }
    default: {
      return state
    }
  }
}

export default vehicles

